jQuery(document).ready(function($) {
	var controller = new ScrollMagic.Controller();

	var container  = $( '#section-anim' );
	var image      = $( '#section-anim .illu--content' );
	var text       = $( '#section-anim .anim-text' );

	var tween = new TimelineMax();
	
	tween.add([
		TweenMax.fromTo( image, 1, {
			y: 100
		}, {
			y: -100
		} ),
		TweenMax.staggerFromTo( text, 0.5, {
			opacity: 0,
			x: 40
		}, {
			opacity: 1,
			x: 0
		}, 0.25 ),
	]);


	var scene = new ScrollMagic.Scene( { triggerElement: container, duration: "80%", offset: -100 } )
	.setTween( tween )
	// .addIndicators({name: "ANIM"})
	.addTo( controller );


	var btnController = new ScrollMagic.Controller();

	var wrap = $('.btnVideo');
	var btn  = $( '#btnVideo' );

	var tweenBtn = TweenMax.fromTo( btn, 1, {
		top: 80,
		opacity: 0
	}, {
		top: 0,
		opacity: 1
	} );

	var sceneBtn = new ScrollMagic.Scene( { duration: "50%" } )
	.setTween( tweenBtn )
	// .addIndicators({name: "ANIM"})
	.addTo( btnController );

		// if ( $('html').is('.no-touchevents') ) {
		// 	var controllerSlider = new ScrollMagic.Controller();

		// 	var slider      = document.getElementById( "slider" );
		// 	var sliderText  = $('.slider__content');
		
		// 	var tween = new TimelineMax();
			
		// 	tween.add([
		// 		TweenMax.fromTo( slider, 1, { y: "0" }, { y: "50%" } ),
		// 		TweenMax.fromTo( sliderText, 1, { y: "0", opacity: "1" }, { y: "-400%", opacity: "0" }  )
		// 	]);

		// 	var sceneSlider = new ScrollMagic.Scene( { triggerHook: "onEnter", duration: "100%" } )
		// 	.setTween( tween )
		// 	//.addIndicators( { name: "SLIDER" } )
		// 	.addTo( controllerSlider );
		// }

		

	// }

	// var controllerNews = new ScrollMagic.Controller();
	// var item = $('#latest-news .item');
	// var tweenNews = TweenMax.staggerFromTo(item, 1, { y: '50%', opacity: 0}, { y: '0%', opacity: 1, ease:Power4.easeOut}, 0.15 );
	// var sceneNews = new ScrollMagic.Scene( { triggerElement: "#latest-news", triggerHook: 'onEnter', duration: "60%" } )
	// .setTween( tweenNews )
	// // .addIndicators({name: "staggering"})
	// .addTo( controllerNews );


	// var card = $('.block--cards .card');
	// var tweenCards = TweenMax.staggerFromTo(card, 0.75, {
	// 	top: 100,
	// 	opacity: 0
	// }, {
	// 	top: 0,
	// 	opacity: 1
	// }, 0.25 );

	// card.hover(over, out);
	// function over() {
	// 	TweenMax.to($(this).find('img'), 4, {
	// 		opacity: 0.2
	// 	})
	// }
	// function out() {
	// 	TweenMax.to($(this).find('img'), 0.3, {
	// 		opacity: 1
	// 	})
	// }
});

